@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot');
    src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
        url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
        url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-SemiBold.eot');
    src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
        url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-SemiBold.woff') format('woff'),
        url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.eot');
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Light.woff') format('woff'),
        url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}



@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.eot');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.eot');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Medium.woff') format('woff'),
        url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-UltraLight.eot');
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url('../fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-UltraLight.woff') format('woff'),
        url('../fonts/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Thin.eot');
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url('../fonts/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Thin.woff') format('woff'),
        url('../fonts/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Black.eot');
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Black.woff') format('woff'),
        url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

