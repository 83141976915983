
.lettsers_slider{
	width: 100%;
	margin-top: 100px;
}

.lettsers_body_slider{
	border-radius: 20px;
	background-color: #e8e8e8;
	margin-right: 50px;
}


.lettsers_body_slider img{
	width: 100%;
	height: 525px;
	object-fit: cover;
}

.lettsers_slider .slide__prev {
	left: 385px; 
}

.lettsers_slider .slide__next {
	right: 437px;
}


















