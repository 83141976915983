/*normolize defaults styles for correct displaying*/
@import url(partials/fonts.css);
* {
  margin: 0;
  box-sizing: border-box; }

body, html {
  height: 100%;
  margin: 0;
  font-family: "Gilroy";
  font-weight: 400;
  background-color: #ffffff; }

a {
  text-decoration: none; }

a, button:focus, input {
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

main {
  display: block; }

h1 {
  font-size: 2em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace,monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

code, kbd, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: .35em .75em .625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

/*normolize defaults styles for correct displaying*/
.main_wrapper {
  max-width: 1600px;
  margin: auto;
  height: 100%; }

.container {
  width: 1345px;
  margin: auto; }

.header {
  width: 100%;
  max-height: 100px;
  background-color: #D71111;
  position: relative;
  z-index: 9999999999; }

.sw {
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  margin-left: auto;
  margin-right: 20px; }

.sw::after {
  display: block;
  content: "";
  width: 60px;
  height: 60px;
  background: url("../img/whatsapp_PNG162.png") no-repeat;
  background-size: cover; }

.h_container {
  max-width: 1583px;
  min-width: 1170px;
  margin: auto;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%; }

.logo {
  width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px; }

.logo img {
  width: 100px;
  height: 100px;
  object-fit: contain; }

.right_side {
  width: 310px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.mobile_call {
  display: none;
  cursor: pointer;
  z-index: 9999999; }

.phones {
  width: 210px;
  max-height: 57px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  transition: all 1s linear;
  z-index: 999999; }

.phones a {
  color: #ffffff;
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.4px; }

.phones.active {
  max-height: 150px;
  padding: 20px; }

.menu_btn {
  cursor: pointer;
  position: relative; }

.menu_btn::before {
  display: flex;
  content: "";
  width: 46px;
  height: 32px;
  background: url("../img/menu.png") no-repeat;
  background-size: contain; }

.goods_slider__item__title {
  color: #6e6e6d;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px; }

.menu {
  display: flex;
  position: absolute;
  top: -120%;
  right: 0;
  transition: top 1s linear;
  background-color: #b81818;
  width: 430px;
  min-height: 500px;
  background-color: #b81818;
  justify-content: center;
  z-index: 999999999; }

.bscroll {
  overflow: hidden; }

.main_menu {
  width: 301px;
  min-height: 505px;
  list-style-type: none;
  padding: 0; }

.menu_btn::after {
  width: 315px;
  height: 101px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: -35px;
  z-index: 9999; }

.main_menu li.active a {
  padding-bottom: 2px;
  color: #000 !important;
  font-weight: 700;
  text-decoration: underline; }

.main_menu li {
  line-height: 60px; }

.main_menu li a {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600; }

.lnh20 {
  line-height: 25px !important; }

.menu.active {
  top: 100px; }

.sub_menu {
  list-style-type: none;
  padding: 0; }

.sub_menu li {
  display: flex;
  align-items: center;
  width: 300px; }

.sub_menu li:before {
  width: 5px;
  height: 5px;
  display: flex;
  align-items: center;
  content: "-";
  color: #fff;
  margin-right: 5px; }

.sub_menu li a {
  font-size: 18px;
  font-weight: 500;
  line-height: 31px; }

.menu:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 100%;
  width: 100%;
  height: 80px;
  display: block; }

.hero {
  width: 100%;
  height: calc(100% - 100px);
  display: flex; }

.pct_block {
  width: calc(100% / 2);
  position: relative;
  transition: all 1s;
  z-index: 999;
  cursor: pointer; }

.pct_block:hover {
  width: 100%;
  z-index: 99999; }

.pct_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.text_block {
  width: 100%;
  color: #ffffff;
  font-size: 24px;
  line-height: 25.61px;
  letter-spacing: 0.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.text_block span {
  width: 235px; }

.advantages {
  width: 100%;
  height: 200px;
  z-index: 99999999999;
  margin-top: -50px; }

.advant_wrapper {
  width: 1095px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto; }

.advantages_item {
  width: 157px;
  height: 157px;
  box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
  background-color: #b81818;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99999999; }

.advantages_item img {
  width: 102px;
  height: 102px;
  object-fit: contain; }

.advantages_item:hover .advantages_info {
  display: flex; }

.advantages_info {
  position: absolute;
  min-width: 350px;
  min-height: 500px;
  box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
  border-radius: 20px;
  background-color: #ffffff;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999999; }

.advantages_title {
  width: 250px;
  color: #6e6e6d;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  margin-bottom: 35px; }

.advantages_desc {
  width: 275px;
  max-height: 140px;
  color: #222222;
  font-size: 18px;
  line-height: 35px;
  overflow: hidden;
  margin-bottom: 15px; }

.advantages_info_img {
  width: 100px;
  height: 102px;
  box-shadow: inset 0 0 2px 1px #ffffff;
  margin-bottom: 35px; }

.advantages_info_img img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.read_more {
  width: 100px;
  height: 17px;
  color: #b81818;
  font-size: 18px;
  font-weight: 300;
  line-height: 35px;
  margin-top: 10px; }

.insane_promo_block_wrapper {
  margin-bottom: 100px; }

.insane_promo_block {
  width: 100%;
  height: 420px;
  box-shadow: 0 0 59px rgba(0, 3, 6, 0.13);
  border-radius: 20px;
  background-color: #ffffff;
  background-image: linear-gradient(-196deg, rgba(255, 255, 255, 0.36) 0%, rgba(222, 229, 245, 0.11) 18%, rgba(184, 24, 24, 0.36) 77%, rgba(255, 255, 255, 0.05) 100%);
  display: flex;
  justify-content: space-evenly; }

.promot_item {
  margin-top: 30px; }

.promo_title_block {
  width: 405px;
  height: 55px;
  box-shadow: 0 0 65px rgba(252, 231, 159, 0.29);
  border-radius: 27px;
  background-color: #484848;
  background-image: linear-gradient(to top, rgba(132, 132, 132, 0.86) 0%, rgba(71, 70, 70, 0.86) 32%, rgba(119, 118, 118, 0.86) 63%, rgba(255, 255, 255, 0.86) 100%);
  color: #ffffff;
  font-family: "Gilroy";
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center; }

.promot_ul {
  max-height: 255px;
  font-size: 21px;
  line-height: 50px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-left: 30px;
  overflow: hidden; }

.promot_ul li a {
  color: #302f2e; }

.useles_shet {
  display: flex;
  width: 45px;
  height: 5px;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 25px; }

.useles_shet::before, .useles_shet::after {
  display: flex;
  content: "";
  min-width: 5px;
  min-height: 5px;
  background-color: #b81818;
  border-radius: 50%; }

.useles_shet2 {
  display: flex;
  min-width: 5px;
  min-height: 5px;
  background-color: #b81818;
  border-radius: 50%; }

.footer {
  width: 100%;
  max-width: 1920px;
  height: 100px;
  color: #ffffff;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 27px;
  background-color: #b81818; }

.footer_wrapper {
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center; }

.footer .logo {
  width: 400px;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px; }

.footer .logo img {
  width: 71px;
  height: 77px; }

.last_btn {
  width: 245px !important;
  height: 72px !important;
  margin: auto;
  margin-top: 25px; }

.sepr_title {
  width: 100% !important;
  text-align: center;
  margin: auto;
  margin-top: 50px; }

.sepr_title h3 {
  font-size: 20px !important; }

.devops {
  width: 160px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  justify-content: flex-end; }

.devops:after {
  display: flex;
  content: "";
  width: 116px;
  height: 48px;
  background: url(../img/gm.png);
  background-size: contain;
  margin-left: 20px; }

.copy {
  width: 275px;
  color: #ffffff;
  font-size: 16px;
  line-height: 27px;
  text-align: center; }

.logo_text {
  margin-left: 10px; }

.footer_address {
  width: 320px;
  height: 44px;
  color: #ffffff;
  line-height: 27px;
  letter-spacing: 1.8px;
  text-align: center; }

.fancybox-container {
  z-index: 999999 !important; }

.modal_form_wrapper {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: none !important;
  box-shadow: 0 0 6px 1px rgba(5, 83, 183, 0.11);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  border-radius: 50px;
  padding: 0 !important;
  margin-top: 70px !important;
  margin-bottom: 40px !important; }

.hidden {
  display: none; }

.comment_modal_form_wrapper {
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.sub_form.comment_form {
  height: 710px; }

.comment_form .form_title {
  margin-top: 10px; }

.comment_form .ms_form button {
  margin: auto;
  margin-top: 25px; }

.comment_form .ms_form {
  justify-content: flex-start;
  flex-direction: column; }

.comment_form .rating-system1 {
  margin-top: 20px; }

.comment_form .one_side_input {
  height: 190px; }

.comment_form .second_side_textarea {
  width: 100%; }

.comment_form .txars {
  width: 100%;
  margin-top: 20px; }

.fancybox-button svg {
  display: none !important; }

.fancybox-content {
  padding: 0; }

.close_btn {
  width: 30px;
  height: 40px;
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  z-index: 999999; }

.close_btn::before {
  content: 'X';
  font-size: 35px;
  font-family: Arial, sans-serif;
  color: #000; }

.close_btn:hover:before {
  cursor: pointer;
  color: #000; }

.read_about_more {
  display: none !important; }

.tab_content strong {
  font-weight: 700; }

.mapbtn {
  display: none !important;
  margin: auto; }

.mobile_friendly {
  display: none;
  width: 100%;
  height: auto;
  flex-wrap: wrap; }

.mob_item {
  width: 100%;
  height: 266px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; }

.mob_item a {
  position: absolute; }

.mob_item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.mob_item:last-child {
  margin-bottom: 0; }

.mob_item .def_gery_btn {
  width: 270px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

.big_span {
  color: #ffffff;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  text-align: center; }

.big_span {
  font-size: 18px;
  font-weight: 500; }

.fancybox-button--close {
  top: 100px;
  background: 0 0;
  right: 30px;
  font-size: 0; }

.fancybox-button--close::after {
  display: block;
  content: "x";
  background: url("../img/close-icon-png-transparent-17.jpg") no-repeat;
  width: 30px;
  height: 30px;
  background-size: contain; }

.main_menu li:nth-child(3) {
  line-height: 30px !important; }

.otziv_slider .slick-list {
  height: 335px !important; }

.service_title_ {
  margin-top: 50px !important; }

.main {
  margin-top: -80px; }

.goods {
  width: 100%;
  max-width: 1920px;
  margin-bottom: 100px; }

.goods_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.goods_left_side {
  width: 480px;
  height: auto; }

.slider {
  width: 470px;
  height: 290px; }

.top_slider img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.top_slider {
  position: relative; }

.linka {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.linka::after {
  display: block;
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  background: url("../img/increase.png") no-repeat;
  bottom: 20px;
  right: 30px; }

.slider-nav {
  width: 470px;
  min-height: 90px;
  max-height: 180px;
  overflow: hidden;
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap; }

.slider-nav .small_body {
  width: 145px;
  height: 90px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px; }

.small_body img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.huge_one {
  height: 365px !important; }

.video_rolik {
  width: 470px;
  height: 265px;
  box-shadow: 10px 12px 10px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  background-color: #2d2d2d;
  margin-top: 110px;
  background: url("../img/video_poster.png"); }

.video_rolik iframe {
  width: 470px !important;
  height: 265px !important; }

.goods_right_side {
  width: 800px;
  height: auto; }

.goods_top_panel {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.goods_title {
  width: 495px;
  color: #6e6e6d;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px; }

.is_in_stock {
  width: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 21px;
  line-height: 35px;
  color: #c8c7c7; }

.is_in.active {
  color: #575757; }

.def_gery_btn {
  width: 215px;
  height: 65px;
  box-shadow: 6px 10px 10px rgba(0, 3, 6, 0.17);
  border: none;
  outline: none;
  background: none;
  border-radius: 31px;
  background-color: #484848;
  background-image: linear-gradient(to top, rgba(132, 132, 132, 0.86) 0%, rgba(71, 70, 70, 0.86) 32%, rgba(119, 118, 118, 0.86) 63%, rgba(255, 255, 255, 0.86) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 5.6px;
  cursor: pointer; }

.goods_tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px; }

.tab_panel {
  width: 100%;
  max-width: 1248px;
  display: flex; }

.tab {
  width: 245px;
  height: 100px;
  box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
  background-color: #ffffff;
  color: #6e6e6d;
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 20px; }

.tab.active {
  color: #ffffff;
  background-color: #D71111; }

.tab_contents {
  width: 860px;
  height: auto;
  box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
  background-color: #ffffff;
  border-radius: 20px;
  z-index: 9999;
  margin-left: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-top: -15px; }

.huge_tabs .tab_contents {
  width: 1150px;
  height: auto;
  margin: auto;
  margin-top: -15px; }

.huge_tabs .tab_panel {
  margin-left: 150px; }

.huge_tabs .tab_content {
  width: 1050px;
  height: auto; }

.another_tbas_wrapper {
  margin: auto;
  height: auto;
  margin-top: 50px; }

.tab_content.active {
  display: block;
  left: 50px; }

.tab_contents p {
  margin-bottom: 30px; }

.tab_content {
  width: 735px;
  height: auto;
  color: #575757;
  font-size: 16px;
  font-weight: 300;
  line-height: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: none;
  transition: all 1s linear;
  left: -100%; }

.tab_content table {
  width: 100% !important; }

.sert_slider {
  margin-top: 20px;
  padding-bottom: 20px;
  min-height: 320px; }

.sert_slider .slick-dots {
  bottom: -12px; }

.sert_body {
  border-radius: 20px;
  background-color: #e8e8e8;
  margin-right: 35px;
  cursor: pointer;
  height: 325px !important;
  width: 228px !important; }

.sert_body img {
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  height: 100%; }

.slide__prev::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: "";
  background: url(../img/left_arrow.png) no-repeat;
  width: 100px;
  height: 100px;
  background-size: contain; }

.sert_slider .slick-track {
  min-width: 1315px !important; }

.slide__next::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: "";
  background: url(../img/right_arrow.png) no-repeat;
  width: 100px;
  height: 100px;
  background-size: contain; }

.slide__prev {
  position: absolute;
  left: -100px; }

.slide__next {
  position: absolute;
  right: -100px; }

.slick___arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9; }

.slick-dots {
  list-style-type: none;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  max-width: 180px; }

.slick-dots li {
  margin-right: 5px; }

.mydots {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c9c9c2;
  cursor: pointer;
  position: relative;
  opacity: 0.5; }

.slick-active .mydots {
  opacity: 1 !important; }

.slick-active .mydots::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #D71111; }

.slick-active button:before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: "";
  width: 8px;
  height: 8px;
  background: #D71111; }

.otziv_slider {
  display: flex !important;
  flex-direction: column;
  min-height: 310px; }

.otziv_body {
  margin-bottom: 20px;
  height: 150px !important;
  overflow: hidden; }

.otziv_dude_name {
  margin-bottom: 0 !important;
  color: #6e6e6d;
  font-size: 21px;
  font-weight: 500;
  line-height: 31px; }

.otziv_text {
  margin-top: 10px;
  width: 100%;
  color: #6e6e6d;
  font-size: 18px;
  font-weight: 300;
  line-height: 31px; }

.rating-system1 {
  width: 135px;
  height: 30px;
  display: inline-block;
  margin-left: -9px;
  position: relative; }

input {
  display: none; }

label {
  float: right;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/star1.png");
  margin: 4px;
  position: relative;
  transition: all .3s; }

.rating-system1 label:before {
  display: flex;
  content: '';
  width: 18px;
  height: 18px;
  background: url("../img/star1.png"); }

.rating-system1:hover input:checked ~ label {
  background: url("../img/star1.png"); }

.rating-system1 input:checked ~ label,
.rating-system1 label:hover ~ label,
.rating-system1 label:hover,
.rating-system1 input:checked ~ label:hover ~ label,
.rating-system1 input:checked ~ label:hover,
.rating-system1 label:hover ~ input:checked ~ label {
  background: url("../img/star2.png"); }

.rating-system1 input:checked ~ label:before {
  background: url("../img/star1.png"); }

.done_stars {
  display: flex;
  width: 135px;
  height: 30px; }

.lstar {
  margin-right: 3px; }

.lstar::before {
  display: flex;
  content: "";
  width: 18px;
  height: 18px;
  background: url("../img/star2.png") no-repeat; }

.sub_form {
  width: 975px;
  height: 650px;
  margin: auto;
  margin-top: 100px;
  box-shadow: 0 0 59px rgba(0, 3, 6, 0.13);
  border-radius: 20px;
  background-color: #ffffff;
  background-image: linear-gradient(-250deg, rgba(255, 255, 255, 0.36) 0, rgba(222, 229, 245, 0.11) 18%, rgba(184, 24, 24, 0.15) 77%, rgba(255, 255, 255, 0.36) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 100px; }

.form_title {
  display: flex;
  flex-direction: column;
  width: 435px;
  color: #727271;
  text-align: center; }

.form_title h1 {
  font-size: 30px;
  font-weight: 600; }

.form_title h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px; }

.ms_form {
  width: 860px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.ms_form button {
  margin-top: 50px; }

.def_inp {
  display: flex;
  width: 400px;
  height: 55px;
  box-shadow: inset 0 0 27px rgba(0, 3, 6, 0.09);
  border: none;
  background: none;
  outline: none;
  border-radius: 20px;
  background-color: #ffffff;
  color: #868686;
  font-size: 18px;
  font-weight: 300;
  line-height: 8px;
  padding: 20px; }

.one_side_input {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.second_side_textarea {
  width: 50%; }

.def_inp input::placeholder, textarea::placeholder {
  opacity: 1 !important;
  color: #868686; }

.txars {
  width: 400px;
  height: 255px;
  box-shadow: inset 0 0 27px rgba(0, 3, 6, 0.09);
  border: none;
  background: none;
  outline: none;
  border-radius: 20px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  resize: none; }

.catalog_huge_tabs .tab_panel {
  margin-left: 150px; }

.sepr_title {
  display: none; }

.last_btn {
  display: none; }

.left_com {
  position: absolute;
  bottom: -85px;
  left: 460px; }

.left_com::after {
  display: flex;
  content: "";
  width: 22px;
  height: 22px;
  background: url("../img/btn_star.png") no-repeat;
  background-size: cover; }

.left_com:hover::after {
  background: url("../img/star2.png") no-repeat; }

.hero_block {
  width: 100%;
  max-width: 1920px;
  height: calc(100% - 100px);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }

.hero_info {
  width: 735px;
  height: auto;
  margin-left: 50px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  position: relative; }

.abts .hero_info::before {
  display: block;
  content: "";
  width: 680px;
  height: 370px;
  background: url("/assets/static/img/banners/banner2x2.png") no-repeat;
  position: absolute;
  top: -150px;
  left: -100px; }

.goods_catalog_slider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.hero_info_title {
  font-size: 48px; }

.hero_info_desc {
  font-size: 30px;
  line-height: 45px; }

.goods_slider__body {
  width: 295px !important;
  margin-right: 50px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.goods_slider__body p {
  margin-bottom: 0;
  margin-top: 10px;
  text-align: center; }

.goods_slider__body img {
  width: 295px;
  max-height: 200px;
  object-fit: contain;
  z-index: 9; }

.img_wrapper {
  position: relative; }

.goods_catalog_slider {
  max-height: 660px; }

.goods_catalog_slider .slick-dots {
  bottom: -30px; }

.img_wrapper:hover::after {
  display: flex;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(184, 24, 24, 0.6);
  z-index: 999; }

.img_wrapper:hover::before {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 51px;
  height: 51px;
  background: url("../img/lupa.png") no-repeat;
  background-size: contain;
  z-index: 9999; }

.one_body {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100%;
  height: auto;
  padding-bottom: 30px; }

.goods_catalog_slider .slick-track {
  min-width: 2100px; }

.goods_catalog_slider .slick-slide {
  min-width: 1050px; }

.about_wrapper {
  width: 100%;
  max-height: 1800px;
  display: flex;
  flex-wrap: wrap;
  transition: all 1s linear;
  overflow: hidden; }

.about_item {
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: space-evenly;
  margin: 50px 0;
  align-items: center; }

.about_item__text {
  width: 500px;
  max-height: 250px;
  color: #575757;
  font-size: 21px;
  line-height: 31px; }

.about_item__text strong {
  font-size: 24px !important;
  font-weight: 600 !important; }

.side_img {
  max-width: 450px;
  height: 250px; }

.side_img img {
  max-width: 500px;
  max-height: 250px;
  object-fit: cover; }

.left_about_side {
  display: flex;
  justify-content: center;
  align-items: center; }

.left_about_side::before {
  display: flex;
  content: attr(data-someyear);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 65px rgba(184, 24, 24, 0.29);
  background-color: #b81818;
  background-image: linear-gradient(to top, rgba(236, 146, 146, 0.84) 0%, rgba(184, 24, 24, 0.84) 31%, rgba(250, 140, 140, 0.84) 65%, rgba(255, 255, 255, 0.84) 100%);
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px; }

.right_about_side {
  position: relative;
  width: 500px; }

.about_item:nth-child(2n+1) .right_about_side::after {
  display: flex;
  content: attr(data-someyear);
  opacity: 0.08;
  font-size: 170px;
  font-weight: 900;
  position: absolute;
  top: 25px;
  left: 45%; }

.about_item:nth-child(2n) {
  flex-direction: row-reverse;
  justify-content: stretch; }

.about_item:nth-child(2n) .right_about_side {
  display: flex;
  justify-content: space-evenly; }

.about_item:nth-child(2n) .right_about_side::before {
  display: flex;
  content: attr(data-someyear);
  opacity: 0.08;
  font-size: 170px;
  font-weight: 900;
  position: absolute;
  top: 25px;
  left: -55%; }

.about_present {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
  align-items: center; }

.side-text {
  width: 670px;
  min-height: 314px;
  color: #575757;
  font-size: 21px;
  line-height: 31px; }

.side-text strong {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px; }

.side-text p {
  margin-bottom: 20px; }

.about_slider {
  width: 550px;
  height: 525px; }

.about_body_slider {
  width: 550px;
  height: 525px;
  border-radius: 20px; }

.about_slider .slick___arrow {
  height: auto;
  top: 50%; }

.about_slider .slide__prev {
  left: -50px; }

.about_slider .slide__next {
  right: -50px; }

.about_slider .slick-dots {
  bottom: -80px; }

.about_body_slider img {
  width: 550px;
  height: 525px;
  object-fit: contain; }

.crumbs {
  width: 90%;
  margin-top: 15px;
  margin-bottom: 30px; }

.crumbs ul {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  height: auto;
  display: flex;
  flex-wrap: wrap; }

.crumbs ul li {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  color: #575757;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 5px; }

.crumbs ul li.active {
  font-weight: 300;
  opacity: 0.7; }

.crumbs ul li.active::after {
  display: none; }

.crumbs ul li a {
  color: #575757; }

.crumbs ul li::after {
  display: flex;
  content: "";
  min-width: 14px;
  min-height: 10px;
  background: url("../img/crar.png") no-repeat;
  margin-left: 15px;
  margin-top: 4px; }

.contacts_wrapper {
  width: 820px;
  margin: auto; }

.nds {
  margin-bottom: 30px; }

.dir_name {
  margin-top: 30px;
  margin-bottom: 30px !important; }

.contacts_wrapper a {
  color: #575757;
  font-size: 16px;
  font-weight: 300;
  line-height: 35px; }

.lettsers_slider {
  width: 100%;
  margin-top: 100px; }

.lettsers_body_slider {
  border-radius: 20px;
  background-color: #e8e8e8;
  margin-right: 50px; }

.lettsers_body_slider img {
  width: 100%;
  height: 525px;
  object-fit: cover; }

.lettsers_slider .slide__prev {
  left: 385px; }

.lettsers_slider .slide__next {
  right: 437px; }

@media screen and (min-width: 1920px) {
  .container {
    width: 1550px;
    margin: auto; }
  .goods_catalog_slider .slick___arrow {
    top: 50%;
    transform: translateY(-50%); }
  .tab {
    width: 320px; }
  .mydots.tab {
    width: 8px;
    height: 8px; }
  .huge_tabs .tab_contents {
    width: 1600px;
    padding: 30px 0; }
  .tab_content.active {
    display: block;
    left: 0; }
  .huge_tabs .tab_content {
    width: 100%;
    padding: 0 50px; }
  .goods_catalog_slider {
    width: 90%;
    margin: auto;
    padding-top: 20px; }
  .goods_catalog_slider .slick-slide {
    width: 1350px !important; }
  .goods_slider__body {
    width: 405px !important;
    margin-right: 45px; }
  .goods_slider__body img {
    max-width: 405px;
    object-fit: cover;
    height: 200px; }
  .goods_slider__body:hover::after {
    width: 405px;
    height: 200px; }
  .goods_slider__body:nth-child(3n) {
    margin-right: 0; }
  .goods_catalog_slider .slick-dots {
    bottom: -5px; }
  .modal_form_wrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sert_slider {
    margin-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px; }
  .goods_slider__item__title {
    font-size: 24px; }
  .sert_body {
    height: auto;
    width: auto; } }

@media screen and (max-width: 1681px) {
  .catalog .tab_contents {
    min-height: auto !important; }
  .goods_catalog_slider .slick-dots {
    bottom: -5px; }
  .goods_catalog_slider .slick___arrow {
    top: 50%;
    transform: translateY(-50%); }
  .catalog .tab {
    width: 320px; }
  .lettsers_slider .slick-dots {
    bottom: -55px; }
  .mydots.tab {
    width: 8px;
    height: 8px; }
  .close_btn {
    right: 340px;
    top: 120px; }
  .modal_form_wrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .goods_slider__item__title {
    font-size: 18px; }
  .sert_body {
    height: 325px !important;
    width: 228px !important; } }

@media screen and (max-width: 1445px) {
  .lettsers_slider .slick___arrow {
    top: 50%;
    transform: translateY(-50%); }
  .catalog_huge_tabs .tab_panel {
    margin-left: 135px; }
  .goods_catalog_slider .slick___arrow {
    top: 50%;
    transform: translateY(-50%); }
  .tab {
    width: 285px; }
  .mydots.tab {
    width: 8px;
    height: 8px; } }

@media screen and (max-width: 1370px) {
  .catalog_huge_tabs .tab_panel {
    margin-left: 0; }
  .catalog .tab {
    width: 295px; }
  .mydots.tab {
    width: 8px;
    height: 8px; }
  .tab_contents {
    width: 845px; }
  .tab {
    width: 245px; }
  .catalog_huge_tabs .tab_panel {
    padding-left: 150px; }
  .goods_catalog_slider .slick-dots {
    bottom: 5px; }
  #con {
    margin-top: 0; }
  #reks {
    margin-top: 0; }
  .about_item:nth-child(2n+1) .right_about_side::after {
    left: 45%; }
  .about_item:nth-child(2n) .right_about_side::before {
    right: 45%; }
  .side-text {
    margin-left: 50px; }
  .about_slider {
    width: 500px;
    margin-right: 50px; }
  .read_about_more {
    display: none; }
  .sub_menu li a {
    font-size: 15px; }
  .main_menu li a {
    font-size: 18px; } }

@media screen and (max-width: 1280px) {
  .container {
    width: 100%;
    margin: auto; }
  .h_container {
    min-width: 100%; }
  .goods_top_panel {
    width: 100%;
    justify-content: center;
    align-items: center; }
  .goods_tabs {
    width: 100%; }
  .goods_right_side {
    width: 100%; }
  .tab_panel {
    width: 100%;
    max-width: 1248px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around; }
  .tab {
    width: 285px;
    height: 93px;
    font-size: 16px;
    line-height: 20px; }
  .tab:last-child {
    margin-right: 0; }
  .tab_contents {
    width: 100%;
    margin-left: 0; }
  .tab_content {
    position: static;
    text-align: justify;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px; }
  .sub_form {
    width: 90%;
    height: 775px; }
  .ms_form {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .one_side_input {
    width: 100%;
    align-items: center; }
  .def_inp {
    width: 90%;
    margin-bottom: 15px; }
  .second_side_textarea {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column; }
  .txars {
    width: 90%; }
  .ms_form button {
    margin-top: 25px; }
  .footer .logo {
    width: 250px; }
  .footer_address {
    display: none; }
  .copy {
    width: 220px;
    font-size: 14px;
    text-align: left;
    line-height: 20px; }
  .logo {
    width: 50%; }
  .logo img {
    width: 59px;
    height: 62px; }
  .logo_text {
    width: 109px;
    height: 79px;
    color: #ffffff;
    font-size: 18px;
    line-height: 21px; }
  .contacts_wrapper {
    width: 90%; }
  #con {
    margin-top: 0; }
  #reks {
    margin-top: 0; }
  .lettsers_slider .slick___arrow {
    top: 50%;
    transform: translateY(-50%); }
  .mydots.tab {
    width: 8px;
    height: 8px; }
  .lettsers_slider .slide__prev {
    left: 370px; }
  .lettsers_slider .slide__next {
    right: 420px; }
  .goods_catalog_slider .slick-dots {
    bottom: -5px; } }

@media screen and (max-width: 1024px) {
  .lettsers_body_slider {
    margin-right: 10px;
    margin-left: 8px; }
  .lettsers_slider .slide__prev {
    left: 295px; }
  .lettsers_slider .slide__next {
    right: 295px; }
  .catalog .tab_content {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0; }
  .catalog .goods_tabs {
    width: 95%;
    margin: auto; }
  .goods_catalog_slider .slick-slide {
    min-width: auto;
    width: 975px; }
  .catalog .huge_tabs .tab_contents {
    width: 100%;
    margin: auto; }
  .catalog_huge_tabs .tab_panel {
    margin-left: 0; }
  .goods_slider__body {
    width: 280px !important;
    height: 200px;
    margin-right: 20px;
    margin-left: 22px; }
  .goods_slider__body img {
    max-width: 280px; }
  .goods_catalog_slider .slick-dots {
    bottom: -5px; } }

@media screen and (max-width: 940px) {
  .lettsers_body_slider {
    margin-right: 6px;
    margin-left: 30px; }
  .lettsers_slider .slide__prev {
    left: 255px; }
  .lettsers_slider .slide__next {
    right: 235px; } }

@media screen and (max-width: 835px) {
  .lettsers_slider .slide__prev {
    left: 250px; }
  .lettsers_slider .slide__next {
    right: 230px; }
  .goods_slider__body {
    width: 220px !important; }
  .goods_slider__body img {
    max-width: 220px; } }

@media screen and (max-width: 770px) {
  .lettsers_slider .slide__prev {
    left: 230px; }
  .lettsers_slider .slide__next {
    right: 205px; }
  .goods_slider__body {
    width: 200px !important;
    height: 155px;
    margin-bottom: 25px; }
  .goods_slider__body img {
    max-width: 200px;
    height: 140px; }
  .goods_slider__body p {
    margin-bottom: 0;
    margin-top: 0; } }

@media screen and (max-width: 480px) {
  .devops {
    margin-left: auto; }
  .hero_block {
    display: none; }
  .main {
    margin-top: 0; }
  .goods_slider__body p {
    padding-top: 6px; }
  .about_body_slider {
    height: auto; }
  .close_btn {
    right: 25px;
    top: 10px; }
  .goods {
    margin-bottom: 50px; }
  .container {
    width: 360px;
    flex-direction: column; }
  .mobile_call {
    display: flex;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    align-items: center; }
  .mobile_call::before {
    display: flex;
    content: "";
    width: 19px;
    height: 19px;
    background: url("../img/call-answer.png") no-repeat; }
  .phones {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 0;
    position: absolute;
    top: 90px;
    z-index: 999999;
    left: 0;
    background-color: #b81818; }
  .phones a {
    font-size: 18px; }
  .goods_wrapper {
    flex-direction: column;
    padding-top: 20px;
    position: relative; }
  .goods_left_side {
    width: 100%;
    margin-top: 80px; }
  .slider-nav {
    width: 100%; }
  .slider-nav .small_body {
    width: 115px;
    height: 80px; }
  .video_rolik {
    width: 100%;
    margin-top: 30px; }
  .video_rolik iframe {
    width: 100% !important; }
  .otziv_body {
    min-height: 150px; }
  .goods_title {
    width: 100%;
    font-size: 18px;
    position: absolute;
    top: 20px;
    left: 0; }
  .is_in_stock {
    width: 190px;
    font-size: 14px;
    position: absolute;
    top: 60px;
    left: 0; }
  .goods_top_panel {
    width: 360px;
    height: 150px;
    justify-content: center;
    align-items: center; }
  .goods_tabs {
    width: 360px; }
  .goods_right_side {
    width: 360px; }
  .tab_panel {
    width: 100%;
    max-width: 1248px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around; }
  .tab {
    width: 129px;
    height: 93px;
    font-size: 16px;
    line-height: 20px; }
  .tab:last-child {
    margin-right: 0; }
  .tab_contents {
    width: 100%;
    margin-left: 0; }
  .tab_content {
    position: static;
    text-align: justify;
    width: 360px;
    font-size: 14px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px; }
  .tab_contents {
    max-height: 1180px; }
  .huge_tabs .tab_panel {
    margin-left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .huge_tabs .tab {
    width: 245px;
    height: 55px;
    margin-bottom: 10px;
    border-radius: 20px;
    margin-right: 0; }
  .another_tbas_wrapper .goods_tabs {
    margin-top: 0; }
  .huge_tabs .tab_contents {
    width: 100%; }
  .huge_tabs .tab_content {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px; }
  .mydots.tab {
    width: 8px;
    height: 8px; }
  .otziv_body {
    min-height: 150px; }
  .otziv_slider {
    height: 200px;
    min-height: auto; }
  #otziv {
    margin: none !important; }
  .slick-dots {
    padding-left: 0; }
  .sert_body {
    margin-right: 0; }
  .otziv_text {
    width: 100%;
    max-width: 250px;
    overflow: hidden; }
  .otziv_slider .slick-dots {
    bottom: 0; }
  .sub_form {
    width: 100%;
    height: 775px; }
  .ms_form {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .one_side_input {
    width: 100%;
    align-items: center; }
  .def_inp {
    width: 90%;
    margin-bottom: 15px; }
  .second_side_textarea {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column; }
  .txars {
    width: 90%; }
  .ms_form button {
    margin-top: 25px; }
  .footer .logo {
    display: none; }
  .footer_address {
    display: none; }
  .copy {
    width: 220px;
    font-size: 14px;
    text-align: left;
    line-height: 20px; }
  .logo {
    width: 50%; }
  .logo img {
    width: 59px;
    height: 62px; }
  .logo_text {
    width: 109px;
    height: auto;
    color: #ffffff;
    line-height: 21px; }
  .right_side {
    width: 30%;
    justify-content: flex-end;
    margin-right: 2%; }
  .h_container {
    min-width: 100%; }
  .menu {
    width: 100%; }
  .main_menu li a {
    font-size: 19px; }
  .sub_menu li a {
    font-size: 14px; }
  .slide__prev::before, .slide__next::before {
    width: 65px;
    height: 65px; }
  .slide__prev {
    left: -60px; }
  .slide__next {
    right: -60px; }
  .sub_form {
    display: none; }
  .about_slider .slick-dots {
    bottom: -20px; }
  .modal_form_wrapper .sub_form {
    display: flex;
    margin-top: 0;
    margin-bottom: 0; }
  .sepr_title {
    display: flex; }
  .last_btn {
    display: flex; }
  .tab_contents {
    display: none;
    max-height: 0; }
  .tab_contents.active {
    display: flex;
    max-height: 10000px; }
  .goods_right_side {
    min-height: 0; }
  .goods_right_side.active {
    margin-bottom: 30px; }
  .hero_block {
    background: none !important;
    height: auto; }
  .hero_info {
    width: 100%;
    height: 100px;
    color: #5f5e5e;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .about_item {
    width: 100%;
    height: auto;
    margin: 0;
    flex-direction: column; }
  .left_about_side {
    align-items: flex-start;
    flex-direction: column; }
  .left_about_side::before {
    margin-right: 0;
    margin-bottom: 20px; }
  .about_item__text {
    width: 100%; }
  .right_about_side {
    flex-direction: row-reverse;
    display: flex;
    width: 100%; }
  .about_item:nth-child(2n+1) .right_about_side::after {
    font-size: 100px;
    left: auto;
    right: 47%; }
  .side_img {
    max-width: 182px;
    height: 175px; }
  .side_img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; }
  .about_item:nth-child(2n) {
    flex-direction: column; }
  .about_item:nth-child(2n) .right_about_side {
    flex-direction: row-reverse;
    display: flex;
    width: 100%;
    justify-content: space-between; }
  .about_item:nth-child(2n) .right_about_side::before {
    font-size: 100px;
    left: auto;
    right: 45%; }
  .about_present {
    width: 100%;
    flex-direction: column;
    padding-top: 30px;
    margin-bottom: 0; }
  .side-text {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0; }
  .about_slider {
    width: 100%;
    height: 285px;
    margin-right: 0; }
  .about_body_slider img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .about_slider .slide__prev {
    left: 0px; }
  .about_slider .slide__next {
    right: 0px; }
  .last_btn {
    margin-bottom: 50px; }
  .contacts {
    min-height: 100vh; }
  .contacts_wrapper {
    width: 100%; }
  .read_about_more {
    display: flex !important;
    margin: auto;
    margin-top: 20px; }
  .about_wrapper {
    max-height: 820px; }
  .about_wrapper.active {
    max-height: 1800px; }
  .tab_content {
    padding-left: 12px; }
  .contacts .tab {
    font-weight: 700;
    font-size: 18px; }
  .contacts .tab_contents {
    min-height: 440px; }
  .contacts .address {
    text-align: left; }
  .dir_name:first-child {
    width: 210px; }
  .dir_name {
    width: 235px; }
  .otziv_slider .slick-list {
    height: 170px !important; }
  .tab_content {
    text-align: left; }
  .contacts.false_contacts {
    min-height: auto; }
  .def_gery_btn.mapbtn {
    margin-top: 50px; }
  .mapbtn {
    display: flex; }
  .lettsers_slider {
    margin-top: 20px; }
  .lettsers_body_slider {
    margin-right: 0;
    margin-left: 0; }
  .lettsers_body_slider img {
    height: 400px; }
  .lettsers_slider .slick___arrow {
    top: 50%;
    transform: translateY(-50%); }
  .lettsers_slider .slide__prev {
    left: -10px; }
  .lettsers_slider .slide__next {
    right: -10px; }
  .lettsers_slider .slick-dots {
    bottom: -35px; }
  .hero_info_title {
    font-size: 26px; }
  .hero_info_desc {
    display: none; }
  .another_tbas_wrapper {
    margin-top: 10px; }
  .one_body {
    flex-direction: column;
    align-items: center; }
  .goods_catalog_slider .slick-slide {
    min-width: auto; }
  .goods_slider__body {
    width: auto;
    height: 200px;
    margin-bottom: 25px;
    margin-right: 0;
    margin-left: 0; }
  .goods_slider__body img {
    max-width: 280px;
    height: 160px; }
  .catalog_huge_tabs .tab_panel {
    padding-left: 0; }
  .huge_tabs .tab_contents {
    margin-top: 0; }
  .tab_contents {
    margin-top: 0; }
  .left_com {
    position: absolute;
    bottom: -85px;
    left: 75px; }
  .sepr_title {
    margin-top: 100px; }
  .comment_form .rating-system1 {
    align-self: flex-start;
    margin-left: 15px; }
  .hero {
    display: none; }
  .advantages {
    display: none; }
  .insane_promo_block_wrapper {
    display: none; }
  .mobile_friendly {
    display: flex; }
  .tab_contents p {
    width: auto;
    line-height: 19px; }
  .sw {
    margin-right: -10px;
    z-index: 99999999; }
  .sw, .sw::after {
    width: 50px;
    height: 50px; } }

@media screen and (max-width: 320px) {
  .tab_contents p {
    width: auto; }
  .container {
    width: 280px; }
  .goods_title {
    font-size: 16px; }
  .slider-nav {
    margin-top: 10px; }
  .slider-nav .small_body {
    width: 85px;
    height: 70px; }
  .video_rolik {
    margin-top: 0px; }
  .goods_right_side {
    width: 280px; }
  .goods_top_panel {
    width: 280px; }
  .goods_tabs {
    width: 280px; }
  .goods_right_side {
    width: 280px; }
  .tab_content {
    width: 280px; }
  .tab_contents {
    max-height: 1625px; }
  .goods_left_side {
    min-height: 540px; }
  .goods_tabs {
    margin-top: 20px; }
  .side_img {
    max-width: 139px;
    height: 175px; }
  .about_item:nth-child(2n+1) .right_about_side::after {
    font-size: 75px; }
  .about_item:nth-child(2n) .right_about_side::before {
    font-size: 75px; }
  .about_slider {
    width: 320px;
    height: 220px; }
  .about_wrapper {
    max-height: 900px; }
  .about_wrapper.active {
    max-height: 2000px; }
  .goods_slider__body {
    width: 100% !important;
    height: 145px;
    margin-right: 0;
    margin-bottom: 25px; }
  .goods_slider__body img {
    max-width: 100%;
    height: 100%; }
  .goods_slider__body p {
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    line-height: 18px; }
  .goods_catalog_slider .slick-dots {
    bottom: -25px; }
  #coment_form {
    margin-top: 75px !important; }
  .left_com {
    position: absolute;
    bottom: -85px;
    left: 32px; }
  .one_body {
    padding-bottom: 45px; } }
