.crumbs{
	width: 90%;
	margin-top: 15px;
	margin-bottom: 30px;
}

.crumbs ul{
	list-style:none;
	padding: 0;
	width: 100%;
	display: flex;
	height: auto;
	display: flex;
	flex-wrap: wrap;
}
.crumbs ul li{
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 20px;
	color: #575757;
	text-decoration: underline;
	font-size: 18px;
	margin-bottom: 5px;
}
.crumbs ul li.active{font-weight: 300;opacity: 0.7}
.crumbs ul li.active::after{display: none;}

.crumbs ul li a{color: #575757;}

.crumbs ul li::after{
	display: flex;
	content: "";
	min-width: 14px;
	min-height: 10px;
	background:url('../img/crar.png')no-repeat;
	margin-left: 15px;
	margin-top: 4px;

}










