.hero_block{
	width: 100%;
	max-width: 1920px;
	height: calc(100% - 100px);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero_info{
	width: 735px;
	height: auto;
	margin-left: 50px;
	color: #ffffff;
	font-weight: 600;
	text-align: center;
	position: relative;

}
.abts .hero_info::before {

    display: block;
    content: "";
    width: 680px;
    height: 370px;
    background: url('/assets/static/img/banners/banner2x2.png') no-repeat;
    position: absolute;
    top: -150px;
    left: -100px;

}


.goods_catalog_slider{
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

}
.hero_info_title{
	font-size: 48px;
}

.hero_info_desc{
	font-size: 30px;
	line-height: 45px;
}

.goods_slider__body{
	width: 295px!important;
	margin-right: 50px;
	margin-bottom: 50px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}
.goods_slider__body p{margin-bottom: 0;margin-top: 10px;text-align: center;}


.goods_slider__body img {

    width: 295px;
    max-height: 200px;
    object-fit: contain;
    z-index: 9;

}

.img_wrapper{
	position: relative;
}

.goods_catalog_slider {
	max-height: 660px;
}

.goods_catalog_slider .slick-dots{
	bottom: -30px;
}


.img_wrapper:hover::after{
	display: flex;
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(184, 24, 24, 0.6);
	z-index: 999;	
} 

.img_wrapper:hover::before {
	display: flex;
	position: absolute;
	top: 50%;
	left:50%;
	transform: translate(-50%, -50%);
	content: "";
	width: 51px;
	height: 51px;
	background:url('../img/lupa.png') no-repeat;
	background-size: contain;
	z-index: 9999;

}
.one_body {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100%;
	 height: auto;
	padding-bottom: 30px;
}

.goods_catalog_slider .slick-track{min-width: 2100px;}
.goods_catalog_slider .slick-slide{min-width: 1050px;}

















































