.main {
    margin-top: -80px;
}
.goods{
	width: 100%;
	max-width: 1920px;
	margin-bottom: 100px;
}
.goods_wrapper{
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}


.goods_left_side{
	width: 480px;
	height: auto;
}

.slider{
	width: 470px;
	height: 290px;
}

.top_slider img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.top_slider{position: relative;}
.linka{
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.linka::after{
	display: block;
	content:"";
	position: absolute;
	width: 48px;
	height: 48px;
	background: url('../img/increase.png') no-repeat;
	bottom: 20px;
	right: 30px;
}
.slider-nav{
	width: 470px;
	min-height: 90px;
	max-height: 180px;
	overflow: hidden;
	display: flex;
	margin-top: 30px;
	flex-wrap: wrap;
}
.slider-nav .small_body{
	width: 145px; 
	height: 90px;
	cursor: pointer;
	margin-right: 10px;
	margin-bottom: 10px;
}
.small_body img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.huge_one {
    height: 365px !important;
}
.video_rolik{
	width: 470px;
	height: 265px;
	box-shadow: 10px 12px 10px rgba(0, 0, 0, 0.17);
	border-radius: 10px;
	background-color: #2d2d2d;
	margin-top: 110px;
	background: url('../img/video_poster.png');
}

.video_rolik iframe{
	width: 470px !important;
	height: 265px !important;
}



.goods_right_side{
	width: 800px;
	height: auto;

}
.goods_top_panel {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.goods_title{
	width: 495px;
	color: #6e6e6d;
	font-size: 30px;
	font-weight: 600;
	line-height: 30px;
}


.is_in_stock {
    width: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
	font-size: 21px;
	line-height: 35px;
	color: #c8c7c7;
}

.is_in.active{color: #575757;}



.def_gery_btn{
	width: 215px;
	height: 65px;
	box-shadow: 6px 10px 10px rgba(0, 3, 6, 0.17);
	border:none;
	outline: none;
	background: none;
	border-radius: 31px;
	background-color: #484848;
	background-image: linear-gradient(to top, rgba(132, 132, 132, 0.86) 0%, rgba(71, 70, 70, 0.86) 32%, rgba(119, 118, 118, 0.86) 63%, rgba(255, 255, 255, 0.86) 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 18px;
	font-weight: 600;
	line-height: 5.6px;
	cursor: pointer;
}
.goods_tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}


.tab_panel {
    width: 100%;
	max-width: 1248px;
    display: flex;
}


.tab{
	width: 245px;
	height: 100px;
	box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
	background-color: #ffffff;
	color: #6e6e6d;
	font-size: 24px;
	font-weight: 700;
	line-height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 20px;
	cursor: pointer;
	margin-right: 20px;
}

.tab.active{
	color: #ffffff;
	background-color: #D71111;
}




.tab_contents{
	width: 860px;
	height: auto;
	box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
	background-color: #ffffff;
	border-radius: 20px;
	z-index: 9999;
	margin-left: -50px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	margin-top: -15px;
}
.huge_tabs .tab_contents {
    width: 1150px;
    height: auto;
    margin: auto;
    margin-top: -15px;
}

.huge_tabs .tab_panel{
	margin-left: 150px;
}
.huge_tabs .tab_content{
	width: 1050px;
	height: auto;
}
.another_tbas_wrapper {
    margin: auto;
    height: auto;
    margin-top: 50px;

}
.tab_content.active{
	display: block;
	left: 50px;
}

.tab_contents p{
	margin-bottom: 30px;
}

.tab_content{
	width: 735px;
	height: auto;
	color: #575757;
	font-size: 16px;
	font-weight: 300;
	line-height: 35px;
	padding-top: 30px;
	padding-bottom: 30px;
	display: none;
	transition: all 1s linear;
	left: -100%;
}

.tab_content table{
	width: 100% !important;

}	
.sert_slider {
    margin-top: 20px;
    padding-bottom: 20px;
    min-height: 320px;
}
.sert_slider .slick-dots {bottom: -12px;}
.sert_body{
	border-radius: 20px;
	background-color: #e8e8e8;
	margin-right: 35px;
	cursor: pointer;
	height: 325px !important;
	width: 228px !important;
}

.sert_body img{
	object-fit: cover;
	border-radius: 20px;
	width: 100%;
	height: 100%;
}


.slide__prev::before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    content: "";
    background: url(../img/left_arrow.png) no-repeat;
    width: 100px;
    height: 100px;
    background-size: contain
}

.sert_slider .slick-track{
	min-width: 1315px !important;
}
.slide__next::before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    content: "";
    background: url(../img/right_arrow.png) no-repeat;
    width: 100px;
    height: 100px;
    background-size: contain;
}

.slide__prev {
    position: absolute;
    left: -100px
}

.slide__next {
    position: absolute;
    right: -100px
}


.slick___arrow {
    position: absolute;
	top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9
}


.slick-dots{
	list-style-type:none;
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
    justify-content: space-between;
    max-width: 180px;
}
.slick-dots li {
	margin-right: 5px;
}
.mydots {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #c9c9c2;
    cursor: pointer;
    position: relative;
    opacity:0.5;
}
.slick-active .mydots {opacity:1 !important;}
.slick-active .mydots::before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background:#D71111;
}

.slick-active button:before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    content: "";
    width: 8px;
    height: 8px;
    background:#D71111;

}


.otziv_slider {
    display: flex !important;
    flex-direction: column;
    min-height: 310px;

}
.otziv_body{
	margin-bottom: 20px;
    height: 150px !important;
    overflow: hidden;
}
.otziv_dude_name{
	margin-bottom: 0 !important;
	color: #6e6e6d;
	font-size: 21px;
	font-weight: 500;
	line-height: 31px;
}

.otziv_text{
	margin-top: 10px;
	width: 100%;
	color: #6e6e6d;
	font-size: 18px;
	font-weight: 300;
	line-height: 31px;
}


.rating-system1 {
	width: 135px;
	height: 30px;
  display:inline-block;
	margin-left: -9px;
  position: relative;
} 

input{
  display:none;
}

label{
  float:right;
  display:inline-block;
  width:18px;
  height:18px;
  background:url('../img/star1.png');
  margin:4px;
  position: relative;
  transition:all .3s;
}
.rating-system1 label:before{
	display: flex;
  content: '';
  width: 18px;
  height: 18px;
  background:url('../img/star1.png');
}
.rating-system1:hover input:checked ~ label{
  background:url('../img/star1.png');
}
.rating-system1 input:checked ~ label,
.rating-system1 label:hover ~ label,
.rating-system1 label:hover,
.rating-system1 input:checked ~ label:hover ~ label,
.rating-system1 input:checked ~ label:hover,
.rating-system1 label:hover ~ input:checked ~ label{
  background:url('../img/star2.png');
}
.rating-system1 input:checked ~ label:before{
  background:url('../img/star1.png');
}  

.done_stars{
	display: flex;
	width: 135px;
	height: 30px;
}
.lstar{
	margin-right: 3px;
}
.lstar::before{
	display: flex;
	content: "";
	width: 18px;
	height: 18px;
	background: url('../img/star2.png') no-repeat;
}








.sub_form{
	width: 975px;
	height: 650px;
	margin:auto;
	margin-top: 100px;
	box-shadow: 0 0 59px rgba(0, 3, 6, 0.13);
	border-radius: 20px;
	background-color: #ffffff;
	background-image: linear-gradient(-250deg,rgba(255,255,255,.36) 0,rgba(222,229,245,.11) 18%,rgba(184,24,24,.15) 77%,rgba(255,255,255,.36) 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 100px;
}




.form_title{
	display: flex;
	flex-direction: column;
	width: 435px;
	color: #727271;
	text-align: center;
}




.form_title h1 {
	font-size: 30px;
	font-weight: 600;
}
.form_title h3 {
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
}


.ms_form{
	width: 860px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.ms_form button{
	margin-top: 50px;
}

.def_inp{
	display: flex;
	width: 400px;
	height: 55px;
	box-shadow: inset 0 0 27px rgba(0, 3, 6, 0.09);
	border:none;
	background: none;
	outline: none;
	border-radius: 20px;
	background-color: #ffffff;
	color: #868686;
	font-size: 18px;
	font-weight: 300;
	line-height: 8px;
	padding: 20px;
}
.one_side_input {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.second_side_textarea {
    width: 50%;
}


.def_inp input::placeholder,  textarea::placeholder {
    opacity: 1!important;
	color: #868686;
}



.txars{
	width: 400px;
	height: 255px;
	box-shadow: inset 0 0 27px rgba(0, 3, 6, 0.09);
	border:none;
	background: none;
	outline: none;
	border-radius: 20px;
	background-color: #ffffff;
	padding-left: 20px;
	padding-top: 20px;
	resize: none;
}



.catalog_huge_tabs .tab_panel {
    margin-left: 150px;
}
.sepr_title{display: none;}
.last_btn{display: none;}

.left_com {position: absolute; bottom: -85px; left: 460px;}
.left_com::after{
	display: flex;
	content:"";
	width: 22px;
	height: 22px;
	background:url("../img/btn_star.png")no-repeat;
	background-size: cover;
}
.left_com:hover::after{
	background:url("../img/star2.png")no-repeat;

}
