.about_wrapper{
	width: 100%;
	max-height: 1800px;
	display: flex;
	flex-wrap: wrap;
	transition: all 1s linear;
	overflow: hidden;
}


.about_item{
	width: 100%;
	height: 270px;
	display: flex;
	justify-content: space-evenly;;
	margin: 50px 0;
	align-items: center;
}



.about_item__text{
	width:500px;
	max-height: 250px;
	color: #575757;
	font-size: 21px;
	line-height: 31px;
 
}

.about_item__text strong{
	font-size: 24px !important;
	font-weight: 600 !important;
}



.side_img {
	max-width: 450px;
	height: 250px;
}

.side_img img{
	max-width: 500px;
	max-height: 250px;
	object-fit: cover;
}

.left_about_side {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left_about_side::before{
	display: flex;
	content: attr(data-someyear);
	width: 80px;
	height: 80px;
	border-radius: 50%;
	box-shadow: 0 0 65px rgba(184, 24, 24, 0.29);
	background-color: #b81818;
	background-image: linear-gradient(to top, rgba(236, 146, 146, 0.84) 0%, rgba(184, 24, 24, 0.84) 31%, rgba(250, 140, 140, 0.84) 65%, rgba(255, 255, 255, 0.84) 100%);
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 24px;
	font-weight: 600;
	margin-right: 20px;
}


.right_about_side{
	position: relative;
	width: 500px;
}

.about_item:nth-child(2n+1) .right_about_side::after{
	display: flex;
	content: attr(data-someyear);
	opacity: 0.08;
	font-size: 170px;
	font-weight: 900;
	position: absolute;
	top: 25px;
	left: 45%;
}

.about_item:nth-child(2n){
    flex-direction: row-reverse;
    justify-content: stretch;
}

.about_item:nth-child(2n) .right_about_side{
	display: flex;
justify-content: space-evenly;	
}
.about_item:nth-child(2n) .right_about_side::before{
	display: flex;
	content: attr(data-someyear);
	opacity: 0.08;
	font-size: 170px;
	font-weight: 900;
	position: absolute;
	top: 25px;
	left: -55%;
}


.about_present {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
	margin-bottom: 150px;
	align-items: center;
}

.side-text{
	width: 670px;
	min-height: 314px;
	color: #575757;
	font-size: 21px;
	line-height: 31px;
}

.side-text strong{
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
}
.side-text p {
    margin-bottom: 20px;
}
.about_slider{
width: 550px;
height: 525px;
}


.about_body_slider{
width: 550px;
height: 525px;
	border-radius: 20px;
}

.about_slider .slick___arrow{
	height: auto;
	top: 50%;
}
.about_slider .slide__prev {
    left: -50px;
}
.about_slider .slide__next {
	right: -50px;
}

.about_slider .slick-dots {
	bottom: -80px;
}
 

.about_body_slider img{
width: 550px;
height: 525px;
	object-fit: contain;
}










































