@media screen and (min-width: 1920px) {
	.container {width:1550px;margin: auto; }
	.goods_catalog_slider .slick___arrow {top: 50%; transform: translateY(-50%); }
	.tab {width: 320px;}
	.mydots.tab {width: 8px; height: 8px;}
	.huge_tabs .tab_contents {width: 1600px; padding: 30px 0;}
	.tab_content.active {display: block; left: 0; }
	.huge_tabs .tab_content {width: 100%;padding: 0 50px;}
	.goods_catalog_slider {width: 90%; margin: auto; padding-top: 20px;}
	.goods_catalog_slider .slick-slide {width: 1350px !important;}
	.goods_slider__body {width: 405px !important; margin-right: 45px;}
	.goods_slider__body img {max-width: 405px; object-fit: cover;height: 200px;}
	.goods_slider__body:hover::after {width: 405px; height: 200px;}
	.goods_slider__body:nth-child(3n){margin-right: 0;}
	.goods_catalog_slider .slick-dots {bottom: -5px; }
	.modal_form_wrapper {margin-top: 0 !important; margin-bottom: 0 !important; } 
	.sert_slider {margin-top: 20px; padding-bottom: 20px; padding-left: 30px; padding-right: 30px; }
	.goods_slider__item__title {font-size: 24px; }
	.sert_body {height: auto; width: auto; }

}


@media screen and (max-width: 1681px) {
	.catalog .tab_contents {min-height: auto !important;}
	.goods_catalog_slider .slick-dots {bottom: -5px; }
	.goods_catalog_slider .slick___arrow {top: 50%; transform: translateY(-50%); }
	.catalog .tab {width: 320px;}
	.lettsers_slider .slick-dots {bottom: -55px;}
	.mydots.tab {width: 8px; height: 8px;}
	.close_btn {right: 340px; top: 120px;}
	.modal_form_wrapper {margin-top: 0 !important; margin-bottom: 0 !important; } 
	 .goods_slider__item__title {font-size: 18px; }
	.sert_body {height: 325px !important; width: 228px !important; }
}


@media screen and (max-width: 1445px) {
	.lettsers_slider .slick___arrow {top: 50%; transform: translateY(-50%); }
	.catalog_huge_tabs .tab_panel {margin-left: 135px; }
	.goods_catalog_slider .slick___arrow {top: 50%; transform: translateY(-50%); }
	.tab {width: 285px;}
	.mydots.tab {width: 8px; height: 8px;}
}



@media screen and (max-width: 1370px) {
	.catalog_huge_tabs .tab_panel {margin-left: 0; }
	.catalog .tab {width: 295px}
	.mydots.tab {width: 8px; height: 8px;}
	.tab_contents{width: 845px;}
	.tab {width: 245px;}
	.catalog_huge_tabs .tab_panel {padding-left: 150px; }
	.goods_catalog_slider .slick-dots {bottom: 5px; }
	#con{margin-top: 0;}
	#reks {margin-top: 0; }
	.about_item:nth-child(2n+1) .right_about_side::after {left: 45%;}
	.about_item:nth-child(2n) .right_about_side::before {right: 45%;}
	.side-text {margin-left: 50px;}
	.about_slider {width: 500px; margin-right: 50px;}
	.read_about_more{display: none;}
	.sub_menu li a {font-size: 15px;}
	.main_menu li a {font-size: 18px;}
}	

@media screen and (max-width: 1280px) {
	.container {width:100%;margin: auto; }
	.h_container {min-width: 100%;}
	.goods_top_panel {width: 100%;justify-content: center; align-items: center; }
	.goods_tabs {width: 100%;}
	.goods_right_side {width: 100%;}
	.tab_panel {width: 100%; max-width: 1248px; display: -webkit-box; display: -ms-flexbox; display: flex; justify-content: space-around; }
	.tab {width: 285px; height: 93px; font-size: 16px; line-height: 20px;}
	.tab:last-child {margin-right: 0; }
	.tab_contents {width: 100%; margin-left: 0;}
	.tab_content {position: static;text-align: justify; width: 100%;padding-top: 30px; padding-bottom: 30px; padding-left: 30px; padding-right: 30px;}

	.sub_form {width: 90%;height: 775px;}
	.ms_form {width: 100%;flex-direction: column; justify-content: space-between;align-items: center;}
	.one_side_input {width: 100%;align-items: center;}
	.def_inp {width: 90%;margin-bottom: 15px;}
	.second_side_textarea {width: 100%; display: flex; align-items: center;flex-direction: column;}
	.txars {width: 90%;}
	.ms_form button {margin-top: 25px; }
	.footer .logo {width: 250px; }
	.footer_address {display: none; }
	.copy {width: 220px; font-size: 14px; text-align: left; line-height: 20px; }
	.logo {width: 50%;}
	.logo img {width: 59px; height: 62px;}
	.logo_text{width: 109px; height: 79px; color: #ffffff; font-size: 18px; line-height: 21px; }
	.contacts_wrapper {width: 90%;}
	#con{margin-top: 0;}
	#reks {margin-top: 0; }
	.lettsers_slider .slick___arrow {top: 50%; transform: translateY(-50%); }
	.mydots.tab {width: 8px; height: 8px;}
	.lettsers_slider .slide__prev {left: 370px; }
	.lettsers_slider .slide__next {right: 420px; }
	.goods_catalog_slider .slick-dots {bottom: -5px; }

}
@media screen and (max-width: 1024px) {
	.lettsers_body_slider {margin-right: 10px; margin-left: 8px; }
	.lettsers_slider .slide__prev {left: 295px; }
	.lettsers_slider .slide__next {right: 295px; }
	.catalog .tab_content {width: 100%; margin-left: 0; padding-left: 0; margin-right: 0; padding-right: 0; }
	.catalog .goods_tabs {width: 95%; margin: auto;}
	.goods_catalog_slider .slick-slide {min-width: auto;width: 975px; }
	.catalog .huge_tabs .tab_contents {width: 100%; margin: auto; }
	.catalog_huge_tabs .tab_panel {margin-left: 0; }
	.goods_slider__body {width: 280px !important; height: 200px; margin-right: 20px; margin-left: 22px; }
	.goods_slider__body img {max-width: 280px; }
	.goods_catalog_slider .slick-dots {bottom: -5px; }

}
@media screen and (max-width: 940px) {
	.lettsers_body_slider {margin-right: 6px; margin-left: 30px; } 
	.lettsers_slider .slide__prev {left: 255px; }
	.lettsers_slider .slide__next {right: 235px; }


}
@media screen and (max-width: 835px) {

	.lettsers_slider .slide__prev {left: 250px; }
	.lettsers_slider .slide__next {right: 230px; }
	.goods_slider__body {width: 220px !important; }
	.goods_slider__body img {max-width: 220px; }



}
@media screen and (max-width: 770px) {
	.lettsers_slider .slide__prev {left: 230px; }
	.lettsers_slider .slide__next {right: 205px; } 
	.goods_slider__body {width: 200px !important; height: 155px;margin-bottom: 25px;}
	.goods_slider__body img {max-width: 200px;height: 140px; }
	.goods_slider__body p {margin-bottom: 0; margin-top: 0; }

}

@media screen and (max-width: 480px) {
	.devops{margin-left: auto;}
	.hero_block{display: none}
	.main {margin-top: 0; }
	.goods_slider__body p {padding-top: 6px; }
	.about_body_slider{height: auto;}
	.close_btn {right: 25px; top: 10px;}
	.goods {margin-bottom: 50px; } 
	.container {width: 360px; flex-direction: column;}
	.mobile_call{display: flex;margin-right: 5px;width: 25px;height: 25px;align-items: center;}
	.mobile_call::before{display: flex; content: ""; width: 19px; height: 19px; background: url('../img/call-answer.png') no-repeat; }
	.phones {display: flex;flex-direction: column; justify-content: center; align-items: center; width: 100%; max-height: 0; position: absolute; top: 90px; z-index: 999999; left: 0; background-color: #b81818;}
	.phones a {font-size: 18px;}
	.goods_wrapper {flex-direction: column;padding-top: 20px;position: relative;}
	.goods_left_side {width: 100%;margin-top: 80px;}
	.slider-nav {width: 100%;}
	.slider-nav .small_body {width: 115px; height: 80px;}
	.video_rolik {width: 100%; margin-top: 30px;}
	.video_rolik iframe {width: 100% !important;}
	.otziv_body {min-height: 150px; }
	.goods_title {width: 100%;font-size: 18px; position: absolute; top: 20px; left: 0; }
	.is_in_stock {width: 190px; font-size: 14px; position: absolute; top: 60px; left: 0; }
	.goods_top_panel {width: 360px; height: 150px; justify-content: center; align-items: center; }
	.goods_tabs {width: 360px;}
	.goods_right_side {width: 360px;}
	.tab_panel {width: 100%; max-width: 1248px; display: -webkit-box; display: -ms-flexbox; display: flex; justify-content: space-around; }
	.tab {width: 129px; height: 93px; font-size: 16px; line-height: 20px;}
	.tab:last-child {margin-right: 0; }
	.tab_contents {width: 100%; margin-left: 0;}
	.tab_content {position: static;text-align: justify; width: 360px; font-size: 14px; padding-top: 30px; padding-bottom: 30px; padding-left: 30px; padding-right: 30px;}
	.tab_contents {max-height: 1180px;}
	.huge_tabs .tab_panel {margin-left: 0; flex-direction: column; justify-content: center; align-items: center; }
	.huge_tabs .tab {width: 245px; height: 55px; margin-bottom: 10px; border-radius: 20px;margin-right: 0;}
	.another_tbas_wrapper .goods_tabs {margin-top: 0;}
	.huge_tabs .tab_contents {width: 100%;}
	.huge_tabs .tab_content {width: 100%;padding-left: 40px;padding-right: 40px;}
	.mydots.tab {width: 8px; height: 8px;}
	.otziv_body {min-height: 150px; }
	.otziv_slider{height: 200px;min-height:auto;}	
	#otziv {margin: none !important; }
	.slick-dots {padding-left: 0;}
	.sert_body {margin-right: 0; }
	.otziv_text {width: 100%;max-width: 250px;overflow: hidden;}
	.otziv_slider .slick-dots {bottom: 0;}
	.sub_form {width: 100%;height: 775px;}
	.ms_form {width: 100%;flex-direction: column; justify-content: space-between;align-items: center;}
	.one_side_input {width: 100%;align-items: center;}
	.def_inp {width: 90%;margin-bottom: 15px;}
	.second_side_textarea {width: 100%; display: flex; align-items: center;flex-direction: column;}
	.txars {width: 90%;}
	.ms_form button {margin-top: 25px; }
	.footer .logo {display: none; }
	.footer_address {display: none; }
	.copy {width: 220px; font-size: 14px; text-align: left; line-height: 20px; }
	.logo {width: 50%;}
	.logo img {width: 59px; height: 62px;}
	.logo_text{width: 109px; height: auto; color: #ffffff; line-height: 21px; }
	.right_side {width: 30%;justify-content: flex-end;margin-right: 2%;}
	.h_container{min-width: 100%;}
	.menu {width: 100%;}
	.main_menu li a {font-size: 19px;}
	.sub_menu li a {font-size: 14px; }
	.slide__prev::before, .slide__next::before {width: 65px; height: 65px;}
	.slide__prev {left: -60px; }
	.slide__next {right: -60px; }
	.sub_form{display: none;}
	.about_slider .slick-dots {bottom: -20px; }
	.modal_form_wrapper .sub_form{display: flex;margin-top: 0;margin-bottom: 0;}
	.sepr_title{display: flex;}
	.last_btn{display: flex;}
	.tab_contents {display: none;max-height: 0;}
	.tab_contents.active {display: flex;max-height: 10000px;}
	.goods_right_side{min-height: 0;}
	.goods_right_side.active{margin-bottom: 30px;}
	.hero_block {background: none !important;height: auto;}
	.hero_info {width: 100%; height: 100px; color: #5f5e5e;margin-left: 0;display: flex;justify-content: center;align-items: center;}
	.about_item {width: 100%; height: auto; margin: 0;flex-direction: column;}
	.left_about_side {align-items: flex-start; flex-direction: column; }
	.left_about_side::before {margin-right: 0; margin-bottom: 20px;}
	.about_item__text {width: 100%; }
	.right_about_side {flex-direction: row-reverse; display: flex;width: 100%; }
	.about_item:nth-child(2n+1)	.right_about_side::after {font-size: 100px; left: auto; right: 47%; }
	.side_img {max-width: 182px; height: 175px; }
	.side_img img {max-width: 100%; max-height: 100%; object-fit: cover; }
	.about_item:nth-child(2n) {flex-direction: column;}
	.about_item:nth-child(2n) .right_about_side {flex-direction: row-reverse; display: flex;width: 100%; justify-content: space-between;}
	.about_item:nth-child(2n) .right_about_side::before {font-size: 100px; left: auto; right: 45%; }
	.about_present {width: 100%; flex-direction: column; padding-top: 30px;margin-bottom: 0;}
	.side-text {width: 100%; margin-bottom: 30px; margin-left: 0;}
	.about_slider {width: 100%; height: 285px; margin-right: 0;}
	.about_body_slider img {width: 100%; height: 100%; object-fit: cover; }
	.about_slider .slide__prev {left: 0px; }
	.about_slider .slide__next {right: 0px; }
	.last_btn {margin-bottom: 50px; }
	.contacts {min-height: 100vh; }
	.contacts_wrapper {width: 100%;}
	.read_about_more {display: flex !important; margin: auto; margin-top: 20px;}
	.about_wrapper{max-height:820px;}
	.about_wrapper.active{max-height: 1800px;}
	.tab_content{padding-left: 12px;}
	.contacts .tab {font-weight: 700; font-size: 18px; }
	.contacts .tab_contents {min-height: 440px; }
	.contacts .address {text-align: left; }
	.dir_name:first-child {width: 210px; }
	.dir_name {width: 235px; }
	.otziv_slider .slick-list {height: 170px !important;}
	.tab_content {text-align: left; }
	.contacts.false_contacts {min-height: auto; }
	.def_gery_btn.mapbtn {margin-top: 50px; }
	.mapbtn{display: flex;}
	.lettsers_slider {margin-top: 20px; }
	.lettsers_body_slider {margin-right: 0; margin-left: 0; }
	.lettsers_body_slider img {height: 400px;}
	.lettsers_slider .slick___arrow {top: 50%; transform: translateY(-50%); }
	.lettsers_slider .slide__prev {left: -10px; }
	.lettsers_slider .slide__next {right: -10px; }
	.lettsers_slider .slick-dots {bottom: -35px; }
	.hero_info_title {font-size: 26px; }
	.hero_info_desc {display: none; }
	.another_tbas_wrapper {margin-top: 10px; }
	.one_body {flex-direction: column;align-items: center;}
	.goods_catalog_slider .slick-slide {min-width: auto; }
	.goods_slider__body {width: auto; height: 200px; margin-bottom: 25px;margin-right: 0; margin-left: 0;}
	.goods_slider__body img {max-width: 280px; height: 160px;}
	.catalog_huge_tabs .tab_panel {padding-left: 0; }
	.huge_tabs .tab_contents {margin-top: 0; }
	.tab_contents {margin-top: 0; }
	.left_com {position: absolute; bottom: -85px; left: 75px; }
	.sepr_title {margin-top: 100px; }
	.comment_form .rating-system1 {align-self: flex-start; margin-left: 15px; }
	.hero {display: none; }
	.advantages {display: none; }
	.insane_promo_block_wrapper {display: none; }
	.mobile_friendly{display: flex;}
	.tab_contents p {width: auto; line-height: 19px;}
	.sw {margin-right: -10px;z-index: 99999999;}
	.sw,.sw::after{width: 50px;height: 50px;}
}



@media screen and (max-width: 320px) {
	.tab_contents p {width: auto; }
	.container {width: 280px; }
	.goods_title {font-size: 16px;}
	.slider-nav {margin-top: 10px; }
	.slider-nav .small_body {width: 85px; height: 70px; }
	.video_rolik {margin-top: 0px; }
	.goods_right_side {width: 280px; }
	.goods_top_panel {width: 280px; }
	.goods_tabs {width: 280px;}
	.goods_right_side {width: 280px;}
	.tab_content {width: 280px;}
	.tab_contents {max-height: 1625px; }
	.goods_left_side {min-height: 540px;}
	.goods_tabs {margin-top: 20px;}
	.side_img {max-width: 139px; height: 175px; }
	.about_item:nth-child(2n+1) .right_about_side::after {font-size: 75px;}
	.about_item:nth-child(2n) .right_about_side::before {font-size: 75px;}
	.about_slider {width: 320px; height: 220px; }
	.about_wrapper {max-height: 900px; }
	.about_wrapper.active{max-height: 2000px;}
	.goods_slider__body {width: 100% !important; height: 145px; margin-right: 0;margin-bottom: 25px;}
	.goods_slider__body img {max-width: 100%; height: 100%; }
	.goods_slider__body p {margin-bottom: 0; margin-top: 0; text-align: center; line-height: 18px;}
	.goods_catalog_slider .slick-dots {bottom: -25px; }
	#coment_form {margin-top: 75px !important; }
	.left_com {position: absolute; bottom: -85px; left: 32px; }
	.one_body {padding-bottom: 45px; }


}













































