.contacts_wrapper{
	width: 820px;
	margin:auto;
}

.nds {
    margin-bottom: 30px;
}

.dir_name {

    margin-top: 30px;
    margin-bottom: 30px !important;

}



.contacts_wrapper a {
    color: #575757;
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;
}



























