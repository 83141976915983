.main_wrapper{
	max-width: 1600px;
	margin:auto;
	height: 100%
}
.container{
	width: 1345px;
	margin:auto; 
}



.header{
	width: 100%;
	max-height: 100px;
	background-color: #D71111;
	position: relative;
	z-index: 9999999999;
}

.sw{
	display: block;
	width: 60px;
	height: 60px;
	position: relative;
	margin-left: auto;
	margin-right: 20px;
}

.sw::after{
	display: block;
	content: "";
	width: 60px;
	height: 60px;
	background:url('../img/whatsapp_PNG162.png')no-repeat;
	background-size: cover;

}

.h_container{
	max-width: 1583px;
	min-width: 1170px;
	margin:auto;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 3%;
	padding-right: 3%;
}


.logo {
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
	color: #ffffff;
	font-size: 24px;
	font-weight: 800;
	line-height: 30px;
}

.logo img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.right_side{
	width: 310px;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;

}

.mobile_call{display: none;cursor: pointer;z-index: 9999999;}
.phones{
	width: 210px;
	max-height: 57px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: hidden;
	transition: all 1s linear;
	z-index: 999999;
}

.phones a{
	color: #ffffff;
	font-size: 23px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 2.4px;
}
.phones.active{max-height: 150px; padding: 20px;}
.menu_btn{
	cursor: pointer;
	position: relative;
}

.menu_btn::before{
	display: flex;
	content:"";
	width: 46px;
	height: 32px;
	background: url('../img/menu.png')no-repeat;
	background-size: contain;
}
.goods_slider__item__title {
    color: #6e6e6d;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.menu{
	display: flex;
	position: absolute;
	top: -120%;
	right: 0;
	transition: top 1s linear;
	background-color: #b81818;
	width: 430px;
	min-height: 500px;
	background-color: #b81818;
	justify-content: center;
	z-index: 999999999;

}

.bscroll{overflow: hidden;}

.main_menu{
	width: 301px;
	min-height: 505px;
	list-style-type: none;
	padding:0;
}

.menu_btn::after {
    width: 315px;
    height: 101px;
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: -35px;
    z-index: 9999;
}

.main_menu li.active a{
	padding-bottom: 2px;
	color: #000 !important;
	font-weight: 700;
	text-decoration: underline;
}
.main_menu li{
	line-height: 60px;
}
.main_menu li a{
	color: #ffffff;
	font-size: 22px;
	font-weight: 600;
}
.lnh20{
    line-height: 25px !important;
}
.menu.active{top: 100px;}
.sub_menu{
	list-style-type: none;
	padding:0;
}
.sub_menu li{
	display: flex;
	align-items: center;
	width: 300px;
}
.sub_menu li:before {
	width: 5px;
	height: 5px;
	display: flex;
	align-items: center;
  content: "-";
  color:#fff;
  margin-right: 5px;
}

.sub_menu li a{
	font-size: 18px;
	font-weight: 500;
	line-height: 31px;
}


.menu:before {
	display: block;
    content: '';
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 80px;
    display: block;

}

.hero {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
}


.pct_block {
    width: calc(100% / 2);
    position: relative;
    transition: all 1s;
	z-index: 999;
	cursor: pointer;
}

.pct_block:hover{
	width: 100%;
	z-index: 99999;
}


.pct_block img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}



.text_block{
	width: 100%;
	color: #ffffff;
	font-size: 24px;
	line-height: 25.61px;
	letter-spacing: 0.6px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}




.text_block span{
	width: 235px;
}



.advantages{
	width: 100%;
	height: 200px;
	z-index: 99999999999;
	margin-top:-50px;
}

.advant_wrapper{
	width: 1095px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin:auto;
}

.advantages_item{
	width: 157px;
	height: 157px;
	box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
	background-color: #b81818;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 99999999;
}




.advantages_item img{
	width: 102px;
	height: 102px;
	object-fit: contain;

}

.advantages_item:hover .advantages_info{
	display: flex;
}


.advantages_info{
	position: absolute;
	min-width: 350px;
	min-height: 500px;
	box-shadow: 0 0 27px rgba(0, 3, 6, 0.11);
	border-radius: 20px;
	background-color: #ffffff;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 999999;

}




.advantages_title{
	width: 250px;
	color: #6e6e6d;
	font-size: 24px;
	font-weight: 700;
	line-height: 35px;
	text-align: center;
	margin-bottom: 35px;
}


.advantages_desc{
	width: 275px;
	max-height: 140px;
	color: #222222;
	font-size: 18px;
	line-height: 35px;
	overflow: hidden;
	margin-bottom: 15px;
}


.advantages_info_img{
	width: 100px;
	height: 102px;
	box-shadow: inset 0 0 2px 1px #ffffff;
	margin-bottom: 35px;
}

.advantages_info_img img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}


.read_more{
	width: 100px;
	height: 17px;
	color: #b81818;
	font-size: 18px;
	font-weight: 300;
	line-height: 35px;
	margin-top: 10px;
}


.insane_promo_block_wrapper{
	margin-bottom: 100px;
}


.insane_promo_block{
	width: 100%;
	height: 420px;
	box-shadow: 0 0 59px rgba(0, 3, 6, 0.13);
	border-radius: 20px;
	background-color: #ffffff;
	background-image: linear-gradient(-196deg, rgba(255, 255, 255, 0.36) 0%, rgba(222, 229, 245, 0.11) 18%, rgba(184, 24, 24, 0.36) 77%, rgba(255, 255, 255, 0.05) 100%);
	display: flex;
	justify-content: space-evenly;
}


.promot_item {

    margin-top: 30px;

}



.promo_title_block{
	width: 405px;
	height: 55px;
	box-shadow: 0 0 65px rgba(252, 231, 159, 0.29);
	border-radius: 27px;
	background-color: #484848;
	background-image: linear-gradient(to top, rgba(132, 132, 132, 0.86) 0%, rgba(71, 70, 70, 0.86) 32%, rgba(119, 118, 118, 0.86) 63%, rgba(255, 255, 255, 0.86) 100%);
	color: #ffffff;
	font-family: "Gilroy";
	font-size: 20px;
	font-weight: 600;
	line-height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}


.promot_ul {
    max-height: 255px;
    font-size: 21px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-top: 20px;
    margin-left: 30px;
	overflow: hidden;
}

.promot_ul li a{
    color: #302f2e;

}

.useles_shet{
	display: flex;
	width: 45px;
	height: 5px;
	justify-content: space-between;
	margin-left: 30px;
	margin-top: 25px;
}
.useles_shet::before, .useles_shet::after{
	display: flex;
	content: "";
	min-width: 5px;
	min-height: 5px;
	background-color: #b81818;
	border-radius: 50%;
}

.useles_shet2{
	display: flex;
	min-width: 5px;
	min-height: 5px;
	background-color: #b81818;
	border-radius: 50%;

}



.footer{
	width: 100%;
	max-width: 1920px;
	height: 100px;
	color: #ffffff;
	font-size: 16px !important;
	font-weight: 500;
	line-height: 27px;
	background-color: #b81818;

}
.footer_wrapper {
    width: 100%;
	padding-left: 3%;
	padding-right: 3%;
    display: flex;
    justify-content: space-between;
    height: 100%;
	align-items: center;
}
.footer .logo{width: 400px;font-weight: 400;font-size: 16px !important;line-height: 20px;}

.footer .logo img {
    width: 71px;
    height: 77px;
}

.last_btn{
	width: 245px !important;
	height: 72px !important;
	margin: auto;
	margin-top: 25px;
}

.sepr_title{
	width: 100% !important;
	text-align: center;
	margin:auto;
	margin-top: 50px;
}
.sepr_title h3 {
    font-size: 20px !important;}

.devops {
    width: 160px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    justify-content: flex-end
}

.devops:after {
    display: flex;
    content: "";
    width: 116px;
    height: 48px;
    background: url(../img/gm.png);
    background-size: contain;
    margin-left: 20px
}

.copy{
	width: 275px;
	color: #ffffff;
	font-size: 16px;
	line-height: 27px;
	text-align: center;
}



.logo_text{margin-left: 10px;}


.footer_address{
	width: 320px;
	height: 44px;
	color: #ffffff;
	line-height: 27px;
	letter-spacing: 1.8px;
	text-align: center;
}







.fancybox-container {z-index: 999999 !important;}

.modal_form_wrapper {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background:none !important;
    box-shadow: 0 0 6px 1px rgba(5, 83, 183, .11);
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
    z-index: 999999;
    border-radius: 50px;
    padding: 0 !important;
	margin-top: 70px !important;
	margin-bottom: 40px !important;
}

.hidden{display: none;}
.comment_modal_form_wrapper{
    height: auto;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.sub_form.comment_form {
    height: 710px;}

.comment_form .form_title {
    margin-top: 10px;
}   

.comment_form .ms_form button {
    margin: auto;
    margin-top: 25px;
}

.comment_form .ms_form {
    justify-content: flex-start;
    flex-direction: column;
}

.comment_form .rating-system1 {
    margin-top: 20px;
}

.comment_form .one_side_input {
    height: 190px;
}


.comment_form .second_side_textarea {
    width: 100%;
}

.comment_form .txars {
    width: 100%;
    margin-top: 20px;
}


.fancybox-button svg {
    display: none !important;
}
.fancybox-content {padding: 0;}
.close_btn {
    width: 30px;
    height: 40px;
    position: absolute;
	right: 25px;
	top: 10px;
	z-index: 99999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    z-index: 999999;
}

.close_btn::before {
    content: 'X';
    font-size: 35px;
    font-family: Arial, sans-serif;
    color: #000;
}

.close_btn:hover:before {
    cursor: pointer;
    color: #000
}


.read_about_more{display: none!important;}

.tab_content strong{font-weight: 700;}
.mapbtn{display: none !important; margin:auto;}



.mobile_friendly{
	display: none;
	width: 100%;
	height: auto;
	flex-wrap: wrap;
}



.mob_item{
	width: 100%;
	height: 266px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}
.mob_item a {
    position: absolute;
}
.mob_item img {

    width: 100%;
    height: 100%;
    object-fit: cover;

}
.mob_item:last-child{margin-bottom: 0;}
.mob_item .def_gery_btn{
	width: 270px;
	height: 75px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.big_span{
	color: #ffffff;
	font-size: 21px;
	font-weight: 600;
	line-height: 30px;
	text-align: center;

}


.big_span{
	font-size: 18px;
	font-weight: 500;
}

.fancybox-button--close {
    top: 100px;
    background: 0 0;
    right: 30px;
    font-size: 0;
}

.fancybox-button--close::after {
    display: block;
    content: "x";
    background: url('../img/close-icon-png-transparent-17.jpg')no-repeat;
    width: 30px;
    height: 30px;
	background-size: contain;
}



.main_menu li:nth-child(3){
    line-height: 30px !important;
}


.otziv_slider .slick-list{height: 335px !important;}

.service_title_ {margin-top: 50px !important;}
